<template>
    <div class="app-container">
        <div class="filter-container">
            <div class="range-filter">
                <div class="label">Claims rejected</div>
                <el-select v-model="period" class="range-selector">
                    <el-option :value="7" label="in the last 7 days" />
                    <el-option :value="14" label="in the last 14 days" />
                    <el-option :value="30" label="in the last 30 days" />
                    <el-option :value="0" label="ever" />
                </el-select>
            </div>

            <div v-if="!loading" class="claims-summary">
                <download-csv :data="exportData" :name="csvFilename">
                    <el-button size="mini" class="item export">
                        Export CSV
                    </el-button>
                </download-csv>

                <el-button
                    disabled
                    size="mini"
                    type="primary"
                    class="item total"
                >
                    Total due: {{ total | currency }}
                </el-button>
            </div>
        </div>

        <claim-list
            v-loading="loading"
            element-loading-text="Loading rejected claims"
            :css="{height: 'calc(100vh - 200px)'}"
            :claims="filteredClaims"
            :scroll="true"
            :sort="sort"
            show_rejections
            @sort-by="sortBy"
            @new-claim="newClaim"
        />

        <claim-modal
            :categories="user_categories"
            :data="user_plan"
            :user="user"
        />
    </div>
</template>

<script>
import ClaimList from '@/views/components/ClaimList';
import ClaimModal from '@/views/components/ClaimModal';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import JsonCSV from 'vue-json-csv';

export default {
    name: 'rejected',
    components: {
        ClaimList,
        ClaimModal,
        'download-csv': JsonCSV,
    },
    props: {
        view: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            claims: [],
            loading: true,
            date: moment(),
            currentView: this.view || 'day',
            sort: {
                column: 'date',
                dir: 'asc',
            },
            period: 7,

            // new claims
            user_categories: [],
            user_plan: [],
            user: {},
            raw_categories: [],
        };
    },
    computed: {
        total() {
            return this.claims.reduce((acc, claim) => acc + claim.total, 0);
        },
        csvFilename() {
            return `claims_rejected_${this.date.format('YYYY-MM-DD')}.csv`;
        },
        exportData() {
            if (this.filteredClaims && this.filteredClaims.length > 0) {
                return this.filteredClaims.map((claim) => {
                    return {
                        date: moment
                            .unix(claim.date.seconds)
                            .format('DD/MM/YYYY'),
                        code: claim.user.code,
                        user: claim.user.name,
                        provider: claim.provider,
                        invoice: claim.invoice_number,
                        amount: claim.total,
                        created_by: claim.created_by
                            ? claim.created_by.name
                            : '',
                        rejected_at: claim.rejected.date.format('DD/MM/YYYY'),
                        rejected_by: claim.rejected.by,
                    };
                });
            }
            return [];
        },
        filteredClaims() {
            // order by time of (last) rejection
            // filter out any claims earlier than display period
            const earliest =
                this.period === 0
                    ? null
                    : moment().startOf('day').subtract(this.period, 'days');
            return this.claims.reduce((list, claim) => {
                if (claim.approval_status === 'rejected') {
                    // last event in claim history should be rejection
                    let rejected = null;
                    const lastEvent = claim.history.slice(-1)[0];
                    if (lastEvent.status === 'rejected') {
                        rejected = {
                            date: moment.unix(lastEvent.date.seconds),
                            by: lastEvent.user_name,
                        };
                    }
                    if (
                        !earliest ||
                        !rejected ||
                        rejected.date.isSameOrAfter(earliest)
                    ) {
                        list.push({
                            ...claim,
                            id: claim.id,
                            rejected,
                        });
                    }
                }
                return list;
            }, []);
        },
    },
    watch: {
        date: function () {
            this.getResults();
        },
        currentView: function (value) {
            if (value === 'day') {
                this.getResults();
            } else {
                this.date = moment(this.date).startOf('week');
                this.getResults();
            }
        },
    },
    mounted() {
        this.getResults();

        // categories for new claim modal
        this.$bind(
            'raw_categories',
            this.$fire.collection('categories').orderBy('order'),
            {maxRefDepth: 0}
        );
    },
    methods: {
        today() {
            this.date = moment();
            this.currentView = 'day';
            this.getResults();
        },
        getResults() {
            this.loading = true;
            let query = this.getQuery();

            query.get().then((snapshot) => {
                if (snapshot.size > 0) {
                    this.$bind('claims', query, {maxRefDepth: 1})
                        .then(() => {
                            // hide spinner after all data has loaded
                            this.sortResults();
                            this.loading = false;
                        })
                        .catch((e) => {
                            console.log('Claims error: ', e);
                            this.$notify.error({
                                title: 'Error',
                                message: 'Unable to load claims',
                            });
                            this.loading = false;
                            Sentry.captureException(e);
                        });
                } else {
                    this.claims = [];
                    this.loading = false;
                }
            });
        },

        getQuery() {
            let query = this.$fire
                .collection('claims')
                .where('approval_status', '==', 'rejected');
            return query;
        },

        nextDate() {
            var count = this.currentView === 'day' ? 1 : 7;
            this.date = moment(this.date).add(count, 'days');
        },
        prevDate() {
            var count = this.currentView === 'day' ? 1 : 7;
            this.date = moment(this.date).subtract(count, 'days');
        },

        sortBy(column) {
            if (column != this.sort.column) {
                this.sort.column = column;
                this.sort.dir = 'asc';
            } else {
                this.sort.dir = this.sort.dir == 'asc' ? 'desc' : 'asc';
            }
            this.sortResults();
        },

        sortResults() {
            let claims = [...this.claims];
            claims.sort((a, b) => {
                // assign comparators based on sort order
                let claim_a = this.sort.dir === 'asc' ? a : b;
                let claim_b = this.sort.dir === 'asc' ? b : a;

                // deal with Firebase Timestamp sort first
                if (this.sort.column === 'date') {
                    return claim_a.date.seconds - claim_b.date.seconds;
                }

                // for total sort
                if (this.sort.column === 'total') {
                    return claim_a.total - claim_b.total;
                }

                // for name, compare user names
                if (this.sort.column === 'name') {
                    claim_a = claim_a.user;
                    claim_b = claim_b.user;
                }

                // for rejected_at, compare rejected dates
                if (this.sort.column === 'rejected_at') {
                    if (!claim_a.rejected && !claim_b.rejected) return 0;
                    if (!claim_a.rejected) return -1;
                    if (!claim_b.rejected) return 1;
                    return claim_a.rejected.date - claim_b.rejected.date;
                }

                // all remaining sortable columns are strings
                let sort_a = claim_a[this.sort.column] || '';
                let sort_b = claim_b[this.sort.column] || '';

                // for created_by sort, compare user names
                if (this.sort.column === 'created_by') {
                    sort_a = claim_a.created_by ? claim_b.created_by.name : '';
                    sort_b = claim_b.created_by ? claim_b.created_by.name : '';
                }

                // for rejected_by, compare rejected user names
                if (this.sort.column === 'rejected_by') {
                    sort_a = claim_a.rejected?.by ?? '';
                    sort_b = claim_b.rejected?.by ?? '';
                }

                return sort_a.localeCompare(sort_b);
            });

            this.claims = claims;
        },

        async getActivePlan(user) {
            let planRefs = {};
            let activePlan = false;
            if (user.plans) {
                let queue = [];
                user.plans.forEach((plan) => {
                    planRefs[plan] = plan;
                    let req = this.$fire
                        .doc(plan)
                        .get()
                        .then((snapshot) => {
                            // plan has loaded
                            planRefs['plans/' + snapshot.id] = snapshot.data();

                            // if not yet set, each time a plan loads check for a current plan
                            if (activePlan === false) {
                                const plans = Object.values(planRefs);
                                activePlan =
                                    this.$options.filters.getActivePlan(plans);
                            }
                        });
                    queue.push(req);
                });
                await Promise.all(queue);
            } else {
                activePlan = null;
            }
            return activePlan;
        },

        async newClaim(user) {
            this.user = user;

            let activePlan = await this.getActivePlan(user);
            if (activePlan) {
                this.user_plan = activePlan.data.map((item) => {
                    return {
                        category: item.category.path,
                        budget: item.budget.path,
                    };
                });

                // get assigned categories for user
                const plan_categories = activePlan.data.map((item) => {
                    return item.category.path;
                });
                this.user_categories = this.raw_categories
                    .filter((raw_cat) => {
                        return plan_categories.includes(
                            `categories/${raw_cat.id}`
                        );
                    })
                    .sort((a, b) => {
                        return a.order - b.order;
                    });
                this.$bus.$emit('claim-modal', {user});
            } else {
                this.$notify.error({
                    title: 'No active plan',
                    message: 'Unable to create a claim for this user',
                });
            }
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pagination-container {
    background-color: white;
    margin: 0;
    padding: 20px;
    border: 1px solid #ebeef5;
    border-top: 0;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    div {
        margin-right: 10px;
    }

    .view-filter {
        margin-bottom: 10px;
    }

    .range-filter {
        font-size: 14px;
        display: flex;
        align-items: center;
    }

    .claims-summary {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .el-button.total {
            cursor: default;
            margin-left: 5px;
        }
    }
}

.full-width {
    width: 100%;
}

.el-tag {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 500;
}
</style>
