var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "range-filter" },
          [
            _c("div", { staticClass: "label" }, [_vm._v("Claims rejected")]),
            _c(
              "el-select",
              {
                staticClass: "range-selector",
                model: {
                  value: _vm.period,
                  callback: function ($$v) {
                    _vm.period = $$v
                  },
                  expression: "period",
                },
              },
              [
                _c("el-option", {
                  attrs: { value: 7, label: "in the last 7 days" },
                }),
                _c("el-option", {
                  attrs: { value: 14, label: "in the last 14 days" },
                }),
                _c("el-option", {
                  attrs: { value: 30, label: "in the last 30 days" },
                }),
                _c("el-option", { attrs: { value: 0, label: "ever" } }),
              ],
              1
            ),
          ],
          1
        ),
        !_vm.loading
          ? _c(
              "div",
              { staticClass: "claims-summary" },
              [
                _c(
                  "download-csv",
                  { attrs: { data: _vm.exportData, name: _vm.csvFilename } },
                  [
                    _c(
                      "el-button",
                      { staticClass: "item export", attrs: { size: "mini" } },
                      [_vm._v(" Export CSV ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "item total",
                    attrs: { disabled: "", size: "mini", type: "primary" },
                  },
                  [
                    _vm._v(
                      " Total due: " +
                        _vm._s(_vm._f("currency")(_vm.total)) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("claim-list", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          "element-loading-text": "Loading rejected claims",
          css: { height: "calc(100vh - 200px)" },
          claims: _vm.filteredClaims,
          scroll: true,
          sort: _vm.sort,
          show_rejections: "",
        },
        on: { "sort-by": _vm.sortBy, "new-claim": _vm.newClaim },
      }),
      _c("claim-modal", {
        attrs: {
          categories: _vm.user_categories,
          data: _vm.user_plan,
          user: _vm.user,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }